import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ValidationErrorHandlerService} from '../../../shared/services/validation-error-handler.service';
import {BaseInputComponent} from '../base-input/base-input.component';
import { AutocompleteDirective } from '../../directives/autocomplete/autocomplete.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgIf, NgFor, SlicePipe } from '@angular/common';

enum PasswordInputType {
    TEXT = "text",
    PASSWORD = "password",
}

@Component({
    selector: 'text-input',
    templateUrl: './text-input.component.html',
    styleUrls: [
        './text-input.component.css',
        '../../common.css',
    ],
    standalone: true,
    imports: [NgClass, MatFormFieldModule, NgIf, MatInputModule, FormsModule, ReactiveFormsModule, AutocompleteDirective, NgFor, SlicePipe]
})
export class TextInputComponent extends BaseInputComponent implements AfterViewInit {

    private readonly _SUPPORTED_TYPES_FOR_CURSOR_POSITION = ["text", "password"];

    @Input() alwaysHighlight ?: boolean = false;

    @Input() hint = '';

    @Input() autoComplete?: string = 'on';

    @ViewChild('input') private input: ElementRef = null;

    showPasswordToggleButton: boolean;

    constructor(
        protected validationErrorHandlerService: ValidationErrorHandlerService,
    ) {
        super(validationErrorHandlerService,);
    }

    ngOnInit() {
        this.showPasswordToggleButton = this.type === PasswordInputType.PASSWORD;
    }

    ngAfterViewInit() {
        // Set the cursor position to the beginning if type is supported
        if (this.input && this._SUPPORTED_TYPES_FOR_CURSOR_POSITION.indexOf(this.type) > -1) {
            this.input.nativeElement.selectionEnd = 0;
        }
    }

    togglePasswordInputType = () => {
        this.type = this.type === PasswordInputType.PASSWORD
            ? PasswordInputType.TEXT
            : PasswordInputType.PASSWORD;
    }

    // added to focus when click on another component
    toggleFocusStateFromParent = () => {
        setTimeout(() => {
            this.input.nativeElement.focus()
        })
    }

    //  added to focus when click on another component
    toggleFocusStateTrue = () => {
        this.isFocused = true;
        this.onFocusStateChange.emit(this.isFocused);
    }
    //  added to focus when click on another component
    toggleFocusStateFalse = () => {
        this.isFocused = false;
        this.onFocusStateChange.emit(this.isFocused);
    }
}
