import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, share, startWith, switchMap } from 'rxjs/operators';
import { Searchable } from '../../../shared/models/searchable';
import { ValidationErrorHandlerService } from '../../../shared/services/validation-error-handler.service';
import { BaseInputComponent } from '../../components/base-input/base-input.component';
import { CHANGE_DEBOUNCE_TIME_MS } from '../../constants/numbers';

export type AutocompleteRefreshCallback<T> = (value?: string, refresh?: boolean) => Promise<T[]>;

/**
 * A directive for encapsulating the autocomplete logic.
 */
@Directive({
    selector: '[autocomplete]',
    standalone: true,
})
export class AutocompleteDirective<T extends Searchable> 
extends BaseInputComponent
implements OnChanges, OnInit {

    /**
     * The items to show as the search results
     */
    @Input() items: T[] = [];

    /**
     * A placeholder to show inside the search input
     * @default "Search"
     */
    @Input() placeholder: string = "Search";

    /**
     * A label to show inside the search input
     */
     @Input() label: string;

    /**
     * Whether to refresh the items list when searching.
     * This input should be accompanied by a refresh callback 
     * @default false
     */
    @Input() refreshOnSearch: boolean = false;

    /**
     * A callback to be called on search.
     * The callback will be fired only if 'refreshOnSearch' is true.
     */
    @Input() refresh: AutocompleteRefreshCallback<T>;

    
    /**
     * Emits an event when the input value changes
     */
    @Output() onInputChange: EventEmitter<any>;

    
    private _refresh: Subject<boolean>;
    protected readonly refresh$: Observable<boolean>;

    filteredItems: Observable<T[]>;

    constructor(protected validationErrorHandlerService: ValidationErrorHandlerService,) { 
        super(validationErrorHandlerService);
        this._refresh = new Subject<boolean>();
        this.refresh$ = this._refresh.asObservable();
        this.onInputChange = new EventEmitter<any>();
        this._setInputControl();
    }

    ngOnChanges(changes: SimpleChanges) {
        const { items } = changes;

        if (items && items.currentValue !== undefined && this.filteredItems === undefined) {
            this.items = items.currentValue;
        }
    }

    ngOnInit() {
        this._setInputValueChangeListener();
    }

    onSearchInput = (value: string) => {
        this.onInputChange.emit(value);
    }

    protected _setInputControl = () => {
        this.inputFormControl = new FormControl("");
    }

    protected _setInputValueChangeListener = () => {
        const filter = this.inputFormControl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(CHANGE_DEBOUNCE_TIME_MS)
            );

        if (this.refreshOnSearch && this.refresh) {
            this.filteredItems = filter.pipe(
                switchMap(async value => {
                    this._setRefreshState(true);
                    const items = await this.refresh(value, true);
                    this._setRefreshState(false);
                    return items;
                }),
                share()
            );
        } else {
            this.filteredItems = filter.pipe(
                map(this._filter)
            );
        }
    }

    protected _filter = (value: string) => {
        if (value) {
            const filterValue = value.toLowerCase();
            return this.items.filter(item => {
                const itemValue = item.getValue();
                if (itemValue) {
                    return itemValue.toLowerCase().includes(filterValue);
                }
            });
        } else {
            return this.items;
        }
    }

    private _setRefreshState = (state: boolean): void => {
        this._refresh.next(state);
    }

}
