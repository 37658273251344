<div
        class="text-input-component"
        [ngClass]="{
        'highlight' : 
            isFocused && 
            highlightOnFocus && 
            inputFormControl?.valid &&
            !readonly,
        'highlight-error': 
            (isFocused || alwaysHighlight) &&
            highlightOnFocus && 
            showValidationErrors &&
            inputFormControl.dirty && 
            inputFormControl?.invalid,
        'no-label': !label,
        'no-value': label && !inputFormControl.value,
        'focus': isFocused
    }">
        <mat-form-field [floatLabel]="label ? 'auto' : 'never'">
            <mat-label *ngIf="label">{{ label }}</mat-label>
            <div class="input-container">
                <input
                    #input
                    type="text"
                    matInput
                    [formControl]="inputFormControl"
                    [placeholder]="placeholder"
                    [readonly]="readonly"
                    [tabIndex]="tabIndex"
                    type="{{ type }}"
                    [autocomplete]="autoComplete"
                    (change)="onValueChange(inputFormControl?.value)"
                    (input)="onValueInput(inputFormControl?.value)"
                    (focus)="toggleFocusStateTrue()"
                    (focusout)="toggleFocusStateFalse()">
                    <div
                        *ngIf="showClearButton && inputFormControl?.value"
                        class="action-button-container">
                        <button
                            type="button"
                            aria-label="clear input"
                            [ngClass]="{
                                'clear-on-error': inputFormControl?.invalid,
                                'clear': inputFormControl?.valid
                            }"
                            (mousedown)="clear()">
                        </button>
                    </div>

                <div
                    *ngIf="readonly && showLockIcon"
                    class="action-button-container">
                    <img [src]="lockIconURL" alt="image">
                </div>

                <div
                    *ngIf="showPasswordToggleButton" 
                    class="action-button-container">
                    <div class="password-cta-container">
                        <button aria-label="Show Password"
                            type="button"
                            class="password" 
                            (mousedown)="togglePasswordInputType($event)">
                        </button>
                    </div>
                </div>

                <!-- A target for future extensions -->
                <div class="action-button-container">
                    <ng-content select="[custom-action-icon]"></ng-content>
                </div>

            </div>
        </mat-form-field>
</div>
<div 
    *ngIf="hint && inputFormControl?.valid" 
    class="error-hint-wrapper">
    <div class="hint-container">
        <div class="hint-msg">{{ hint }}</div>
    </div>
</div>
<div
    *ngIf="showValidationErrors && !inputFormControl?.pristine && inputFormControl?.invalid"
    class="input-errors-container">
    <div
        *ngFor="let msg of errorMessages | slice:0:1"
        class="input-error-msg">
        {{ msg }}
    </div>
</div>

