import {Directive, EventEmitter, Injectable, Input, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationErrorHandlerService } from '../../../shared/services/validation-error-handler.service';

/**
 * A base component for input fields (i.e: text-inputs, text-area-inputs, etc).
 */
@Directive()
export abstract class BaseInputComponent {

    /**
     * The FormControl instance
     */
    @Input() inputFormControl: FormControl;

    /**
     * Text to be shown inside the input field when it is empty
     */
    @Input() placeholder: string;
    
    /**
     * The field name that will be shown while typing inside the input field and as a placeholder when the input field is empty
     */
    @Input() label: string;
    
    /**
     * A flag indicating if the input field should have a border when in focus. 
     * @default true
     */
    @Input() highlightOnFocus: boolean = true;
    
    /**
     * A flag indicating whether a clear button should appear if the input field contains text.
     * @default true
     */
    @Input() showClearButton: boolean = true;

    /**
     * A flag that disable text edit.
     * A lock icon is added when flag sets to true
     * @default false
     */
    @Input() readonly: boolean = false;

    /**
     * A flag for showing validation errors if exists
     * @default true;
     */
    @Input() showValidationErrors: boolean = true;

    /**
     * The type of input (i.e: password, email, etc.)
     * @default text;
     */
    @Input() type: string = "text";




    /**
     * A flag for showing a lock icon for readonly inputs
     * @default true (if readonly property is true)
     */
    @Input() showLockIcon: boolean = true;

     /**
     * The input's tab index
     * @default 0
     */
    @Input() tabIndex: number = 0;

    /**
     * An event emitter that will be fired when the text input changes and loses focus.
     */
    @Output() onChange: EventEmitter<string>;

    /**
     * An event emitter that will be fired when the text input changes.
     */
    @Output() onInput: EventEmitter<string>;

    /**
     * An event emitter that will be fired when the input's focus state changes
     */
    @Output() onFocusStateChange: EventEmitter<boolean>;

    readonly lockIconURL = '/img/locked.svg';

    isFocused: boolean;
    errorMessages: string[];

    constructor(protected validationErrorHandlerService: ValidationErrorHandlerService,) { 
        this.onChange = new EventEmitter<string>();
        this.onInput = new EventEmitter<string>();
        this.onFocusStateChange = new EventEmitter<boolean>();
    }

    getErrorMessages = (): string[] => {
		return this.validationErrorHandlerService.getErrorMessages(
			this.inputFormControl,
			this.getErrorCodes()
		);
	}

    getErrorCodes = (): string[] => {
        if (this.inputFormControl) {
            return Object.keys(this.inputFormControl.errors || []);
        }
    }

    onValueChange = (value: string) => {
        if (this.onChange) {
            this.onChange.emit(value);
        }
    }

    onValueInput = (value: string) => {
        if (this.onInput) {
            this.onInput.emit(value);
        }

        if (this.showValidationErrors) {
            this.errorMessages = this.getErrorMessages();            
        }
    }

    toggleFocusState = () => {
        this.isFocused = !this.isFocused;
        this.onFocusStateChange.emit(this.isFocused);
    }

    clear() {
        this.inputFormControl.setValue("");
        this.inputFormControl.markAsDirty();
        this.onValueInput("");
    }

}
