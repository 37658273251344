import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidationErrorHandlerService {

    getErrorMessages = (control: FormControl, errorCodes: string[]): string[] => {
        const msgs: string[] = [];
        
        errorCodes.forEach(code => {
            if (control.hasError(code)) {
                let msg = "";
                switch (code) {
                    case 'required':
                        msgs.push("This field is required");
                        break;
                    case 'email':
                        msgs.push("Please enter a valid email address");
                        break;
                    case 'phone':
                        msgs.push("Phone number should be in International format. Example: +1(347) 669-7177");
                        break;
                    case 'optionMatch':
                        msgs.push("Please choose a valid option from the menu");
                        break;
                    case 'minlength':
                        msgs.push(`This field requires at least ${control.errors.minlength.requiredLength} characters`);
                        break;
                    case 'maxlength':
                        msgs.push(`This field can't have more than ${control.errors.maxlength.requiredLength} characters`);
                        break
                    case 'min':
                        const minValue = control.errors.min.min;
                        msg = minValue === 0 
                            ? 'This field must be a positive number' 
                            : `This field can't have a value less than ${minValue}`;
                        msgs.push(msg);
                        break;
                    case 'pattern':
                        msgs.push("Please enter a matching value");
                        break;
                    case 'mustMatch':
                        const controlName: string = control.errors.name || "field";
                        msgs.push(`${this._capitalize(controlName)} does not match`);
                        break;
                    case 'messages':
                        msg = Object.values(control.errors[code])[0] as string;
                        msgs.push(msg);
                        break;
                    default:
                        /**
                         * For custom codes use the code itself as the error key
                         */
                        msgs.push(control.errors[code]);
                        break;
                }
            }
        });

        return msgs;
    }

    private _capitalize = (value: string): string => {
        return value[0].toUpperCase() + value.slice(1);
    }
}
